import './login.css'
import {API, UI} from "../../utils";
import {azure_icon, google_icon, user_img} from './assets'

const Login = () => {

    const loginHandler = (service) => {
        window.location = (`${API}auth/${service}/login?redirect_url=${UI}`)
    }

    return (
        <div className="login">
            <img className="userImg" src={user_img} alt="user img"/>

            <button className="login-btn" value="Google Login" onClick={() => loginHandler("google")}>
                <img className="service-icon" src={google_icon} alt="google_image"/>
                <span className="loginText">Sign in with Google</span>
            </button>

            <button className="login-btn second-btn" value="Azure Login" onClick={() => loginHandler("azure")}>
                <img className="service-icon" src={azure_icon} alt="azure_image"/>
                <span className="loginText">Sign in with Azure</span>
            </button>

            <p className="footer">© {new Date().getFullYear()} Fission Consulting, LLC</p>
        </div>
    )
}

export default Login;
